body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
  /*font-family: 'nexon-lv1-gothic-otf',
  sans-serif;*/
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

.tui-editor-contents img {
  width: 100%;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}

select {
  appearance: none;
}